import { createStore } from 'vuex';
// 引入持久化
import createPersistedState from 'vuex-persistedstate';

export default createStore({
	// 存储数据
	state: {
		// 用户信息
		userInfo: {},
		// 用户线索信息
		lineInfo: {},
		searchInfo: {},
		// 公海搜索
		seaInfo: {},
		// 路由
		routerPath: '',
		routerBool: {},
		// 线索列表导航数据
		listData: {
			data1: 0,
			data2: 0,
			data3: 0,
			data4: 0,
			data5: 0,
		},
		// 交易列表导航数据
		numData: {
			data1: 0,
			data2: 0,
		},
		// 退款审核导航数据
		refund: {
			data1: 0,
			data2: 0,
			data3: 0,
		},
		thread_id: '', //教学学员管理线索id
		course_id: '', //教学学员管理课程id
		schedule_id: '', //教学学员管理期数id
		loading: false, //加载bool
		questions: '', //问题管理待回答问题数量
		questionAnswers: '', //问题管理待回答问题数量
	},
	// 存储复杂数据
	getters: {
		GetLineInfo(state) {
			return state.lineInfo;
		},
	},
	// 存储函数方法
	mutations: {
		// 存储用户信息
		loginSuccess(state, userInfo) {
			state.userInfo = userInfo;
		},
		//退出登录清除用户信息
		loginOut(state) {
			state.userInfo = {};
		},
		// 存储用户线索
		setUserId(state, lineInfo) {
			state.lineInfo = lineInfo;
		},
		// 搜索调用
		searcher(state, searchInfo) {
			state.searchInfo = searchInfo;
		},
		// 重置调用
		reseter(state) {
			state.searchInfo = {};
		},
		// 公海搜索调用
		seaSearch(state, seaInfo) {
			state.seaInfo = seaInfo;
		},
		// 路由跳转打印
		setRouter(state, routerPath) {
			state.routerPath = routerPath;
		},
		// 路由布尔值
		setRouterBool(state, routerBool) {
			state.routerBool = routerBool;
		},
		// 存储线索列表导航数据
		setlistData(state, listData) {
			state.listData.data1 = listData.data1;
			state.listData.data2 = listData.data2;
			state.listData.data3 = listData.data3;
			state.listData.data4 = listData.data4;
			state.listData.data5 = listData.data5;
		},
		setQuestions(state, questions) {
			state.questions = questions;
		},
		setQuestionAnswers(state, questionAnswers) {
			state.questionAnswers = questionAnswers;
		},
	},
	// 异步调用函数
	actions: {},
	// 模块
	modules: {},

	//持久化数据
	plugins: [
		createPersistedState({
			// 存储方式：localStorage、sessionStorage、cookies
			storage: window.sessionStorage,
			// 存储的 key 的key值
			key: 'store',
			render(state) {
				// 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
				return { ...state };
			},
		}),
	],
});
