<template>
  <div>
    <!-- 4.使用 -->
    <el-config-provider :locale="locale">
      <router-view v-if="isRouterAlive" />
    </el-config-provider>
  </div>
</template>
<script>
//1.引入
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import { ref, provide } from "vue"; //要引入方法
export default {
    data () {
        return {};
    },
    //2.挂载
    components: {
        ElConfigProvider,
    },
    //3.设置
    setup () {
        // 局部组件刷新
        const isRouterAlive = ref(true);
        const reload = () => {
            isRouterAlive.value = false;
            setTimeout(() => {
                isRouterAlive.value = true;
            }, 1000);
        };

        provide("reload", reload);
        return {
            isRouterAlive,
            locale: zhCn,
        };
    },
    created () {

    },
    computed: {},
    methods: {},
};
</script>
<style>
 
 .el-table--enable-row-hover .el-table__body tr:hover>td{
 
 background-color: #f0f5ff !important;
  
 }
/* @import "assets/css/reset.css" */
@import url("assets/css/reset.css");

::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
}

::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 167, 232, 0.3);
}

::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}
</style>
