export default {
    //自定义节流操作
    my_one_click: {
        mounted (el, binding) {
            el.addEventListener('click', () => {
                if (!el.disabled) {
                    el.disabled = true;
                    setTimeout(() => {
                        el.disabled = false;
                    }, binding.value || 1000);
                }
            })
        }
    },
    myInput: {
        mounted: (el, binding) => {
            const input = el.getElementsByTagName('input')[0];
            if (input) {
                // 调用input方法，加入正则校验的逻辑
                input.onkeyup = function (e) {
                    if (input.value.length === 1) {
                        input.value = input.value.replace(/[^0-9]/g, '');
                    } else {
                        input.value = input.value.replace(/[^\d]/g, '');
                    }
                    // 调用自定义事件
                    trigger(input, 'input');
                };
                input.onblur = function (e) {
                    if (input.value.length === 1) {
                        input.value = input.value.replace(/[^0-9]/g, '');
                    } else {
                        input.value = input.value.replace(/[^\d]/g, '');
                    }
                    trigger(input, 'input');
                };
            }
        },
        updated (el, binding) {

        },
        unmounted (el) {

        }
    },
}


function trigger (el, type) {
    const e = document.createEvent('HTMLEvents');
    // 初始化默认值
    e.initEvent(type, true, true);
    // 触发自定义事件
    el.dispatchEvent(e);
};


